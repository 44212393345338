<template>
	<div class="login-container" v-if="computedValue === `ko`">
		<div class="form">
			<div class="form__group">
				<!--아이디-->
				<label for="inputId">아이디</label>
				<input
					class="form__group--input"
					id="inputId"
					v-model="form.userId"
					type="text"
					required="required"
					placeholder="아이디"
					maxlength="12"
					@keyup.enter="handleLogin()"
				/>
			</div>

			<!--비밀번호-->
			<div class="form__group">
				<label for="inputPw">비밀번호</label>
				<input
					v-show="toggleOn"
					class="form__group--input"
					id="inputPw"
					v-model="form.userPw"
					type="password"
					required="required"
					placeholder="비밀번호"
					maxlength="20"
					@keyup.enter="handleLogin()"
				/>
				<input
					v-show="!toggleOn"
					class="form__group--input"
					id="inputPw"
					v-model="form.userPw"
					type="text"
					required="required"
					placeholder="비밀번호"
					maxlength="20"
					@keyup.enter="handleLogin()"
				/>
				<div>
					<template v-if="isPopup === true">
						<button class="btn__show-pw" @click="toggleClass">
							<i v-show="toggleOn" class="fa fa-eye fa-md show-pw fa-2x"></i>
							<i
								v-show="!toggleOn"
								class="fa fa-eye-slash fa-md show-pw fa-2x"
							></i>
						</button>
					</template>
					<template v-else>
						<button class="btn__show-pw" @click="toggleClass">
							<i v-show="toggleOn" class="fa fa-eye fa-md show-pw"></i>
							<i v-show="!toggleOn" class="fa fa-eye-slash fa-md show-pw"></i>
						</button>
					</template>
				</div>
			</div>
			<router-link to="/findpw/form" class="link__pw"
				>비밀번호 찾기</router-link
			>
			<button
				type="submit"
				class="btn__form"
				:class="{ btnDisabled: !loginCheck }"
				:disabled="!loginCheck"
				@click="handleLogin()"
			>
				로그인
			</button>

			<div class="form__link--signup">
				<span>계정이 없으신가요?&nbsp;&nbsp;</span>
				<router-link to="/signup">회원가입</router-link>
			</div>
		</div>
	</div>
	<div class="login-container" v-else-if="computedValue === `en`">
		<div class="form">
			<div class="form__group">
				<!--아이디-->
				<label for="inputId">Username</label>
				<input
					class="form__group--input"
					id="inputId"
					v-model="form.userId"
					type="text"
					required="required"
					placeholder="Username"
					maxlength="12"
					@keyup.enter="handleLogin()"
				/>
			</div>

			<!--비밀번호-->
			<div class="form__group">
				<label for="inputPw">Password</label>
				<input
					v-show="toggleOn"
					class="form__group--input"
					id="inputPw"
					v-model="form.userPw"
					type="password"
					required="required"
					placeholder="Password"
					maxlength="20"
					@keyup.enter="handleLogin()"
				/>
				<input
					v-show="!toggleOn"
					class="form__group--input"
					id="inputPw"
					v-model="form.userPw"
					type="text"
					required="required"
					placeholder="Password"
					maxlength="20"
					@keyup.enter="handleLogin()"
				/>
				<div>
					<template v-if="isPopup === true">
						<button class="btn__show-pw" @click="toggleClass">
							<i v-show="toggleOn" class="fa fa-eye fa-md show-pw fa-2x"></i>
							<i
								v-show="!toggleOn"
								class="fa fa-eye-slash fa-md show-pw fa-2x"
							></i>
						</button>
					</template>
					<template v-else>
						<button class="btn__show-pw" @click="toggleClass">
							<i v-show="toggleOn" class="fa fa-eye fa-md show-pw"></i>
							<i v-show="!toggleOn" class="fa fa-eye-slash fa-md show-pw"></i>
						</button>
					</template>
				</div>
			</div>
			<router-link to="/findpw/form" class="link__pw"
				>Forgot Password</router-link
			>
			<button
				type="submit"
				class="btn__form"
				:class="{ btnDisabled: !loginCheck }"
				:disabled="!loginCheck"
				@click="handleLogin()"
			>
				Login
			</button>

			<div class="form__link--signup">
				<span>Don’t have an account?&nbsp;&nbsp;</span>
				<router-link to="/signup">Sign Up</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import userinfoDataService from '../services/userinfoDataService';

export default {
	data() {
		return {
			form: {
				userId: '',
				userPw: '',
			},
			show: true,
			toggleOn: true,
		};
	},
	props: {
		isPopup: Boolean,
	},
	computed: {
		formCheck() {
			if (this.form.userId.length != '' && this.form.userPw.length != '') {
				return true;
			} else {
				return false;
			}
		},
		loginCheck() {
			if (this.formCheck == true) {
				return true;
			} else {
				return false;
			}
		},
		computedValue() {
			return this.$store.state.language;
		},
	},
	methods: {
		toggleClass() {
			this.toggleOn = !this.toggleOn;
		},
		handleLogin() {
			this.loading = true;

			if (this.form.userId && this.form.userPw) {
				const user = {
					userID: this.form.userId,
					userPW: this.form.userPw,
				};

				userinfoDataService
					.signin(user)
					.then(res => {
						// 로그인 성공 시
						if (res.data.message == 'SUCCESS') {
							userinfoDataService.storeUserData(res.data.token);
							if (this.isPopup === false) {
								this.$router.push('/');
							}
							// 로그인 아이디 헤더에 표시하기 위한 코드
							//back에서 받은 기업 구분값(현재 임시로 comYn 설정, 설정은 바꾸셔도 됩니다) sotre에 넣어놨어요!
							this.$store.commit('setUserid', user.userID);
							this.$store.commit('setUserCom', res.data.comYn);
							console.log(
								'로그인 시 기업회원 유무가 들어오나?',
								res.data.comYn,
							);
						}
					})
					.catch(err => {
						console.log(err);
						alert('회원정보를 다시 확인해주세요.');
					});
			}
		},
	},
};
</script>

<style></style>
