<template>
	<div v-if="computedValue === `ko`">
		<main-header></main-header>
		<h1 class="page-title">로그인</h1>
		<login-form :isPopup="false"></login-form>
		<Footer />
	</div>
	<div v-else-if="computedValue === `en`">
		<main-header></main-header>
		<h1 class="page-title">Login</h1>
		<login-form :isPopup="false"></login-form>
		<Footer />
	</div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue';
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';

export default {
	components: { LoginForm, MainHeader, Footer },
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
	},
};
</script>

<style></style>
